.CashTips {
  width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.CashTipsHeading {
  text-align: center;
  font-size: var(--h1-size);
  font-family: var(--font-family-header);
  white-space: nowrap;
}
