.Form {
  width: 100%;
}

.Form:invalid button[type="submit"] {
  cursor: not-allowed;
  opacity: 0.5;
}

.InlineForm {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}
