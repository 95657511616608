:root {
  --header-height: 50px;
  --header-logo-width: 136px;
}

.Header {
  align-items: center;
  background-color: var(--color-primary);
  display: flex;
  font-family: var(--font-family);
  min-height: var(--header-height);
  text-align: center;
}

.HeaderLink {
  height: auto;
  margin: 0 auto;
  padding-top: 10px;
}

.HeaderLogo {
  max-width: var(--header-logo-width);
}

.LogoutButton {
  width: 200px;
  color: white;
  text-decoration: none;
}

.ButtonWrapper {
  margin-right: 87px;
  background-color: black;
  color: white;
  font-size: 16px;
  padding: 10px;
  border-radius: var(--base-border-radius);
}