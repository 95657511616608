.Reports {
  display: flex;
  flex-direction: var(--flex-direction);
  flex-wrap: wrap;
}

.ReportBuilderSection {
  flex: 1 1 50%;
  padding-left: calc(var(--base-gutter) / 2);
  padding-right: calc(var(--base-gutter) / 2);
}

.ReportBuilderSection:first-of-type {
  padding-left: 0;
}
.ReportBuilderSection:last-of-type {
  padding-right: 0;
}
