.Label {
  cursor: pointer;
  display: block;
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
}
.LabelFull {
  flex: 1 0 100%;
}

.Checkbox {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.Input {
  appearance: none;
  background-color: var(--color-primary);
  border-radius: var(--base-border-radius);
  border: 1px solid transparent;
  cursor: pointer;
  display: block;
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  padding: var(--base-gutter);
  width: 100%;
}

.Input[type="checkbox"] {
  height: 0.5em;
  margin-right: 0.5em;
  position: relative;
  width: 0.5em;
}

.Input[type="checkbox"]::before {
  font-size: 2em;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.Input[type="checkbox"]:checked::before {
  content: '✓';
}

.Input[hidden] {
  display: none;
}

.Input:invalid {
  border-color: var(--color-error);
}

.Input:disabled {
  cursor: not-allowed;
}

.Input[readonly] {
  cursor: default;
}

.InputError {
  background: var(--color-error);
  padding: calc(var(--base-gutter) / 3) var(--base-gutter);
  color: var(--color-primary);
  font-size: calc(var(--base-font-size) / 1.5);
  text-align: center;
  border-radius: 0 0 var(--base-border-radius) var(--base-border-radius);
}
