.Modal[open] {
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 4px 2px var(--color-secondary);
  display: block;
  height: -webkit-fit-content;
  left: 50%;
  padding: var(--base-gutter);
  position: fixed;
  top: 50%;
  transform:
    translate3d(-50%, -50%, 0);
  width: -webkit-fit-content;
}

.backdropped::backdrop {
  background-color: white; /* 🤯 var() does not work */
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: fixed;
  right: 0;
  top: 0;
}

.Modal menu {
  margin-top: 1em;
}
