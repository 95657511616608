.Table {
  border-collapse: collapse;
  margin-bottom: var(--base-gutter);
  margin-top: var(--base-gutter);
  width: 100%;
}

.Table thead {
  text-align: left;
  text-transform: capitalize;
  white-space: nowrap;
}

.Table thead tr,
.Table tbody tr {
  border-bottom: 1px solid var(--color-tertiary);
}

.Table td,
.Table th {
  padding: 0.2em;
}

.Table td:first-child,
.Table th:first-child {
  padding-left: 0;
}

.Table td:last-child,
.Table th:last-child {
  padding-right: 0;
}

.Table td[data-type="numeric"],
.Table th[data-type="numeric"] {
  text-align: right;
}
