.HeroImage {
  border-collapse: collapse;
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PageWrapper {
  display: flex;
  align-content: center;
}
.Wrapper {
  width: 50%;
  display: inline-block;
  background-color: white;
  height: 100vh;
  position: relative;
}

.FormWrapper {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  padding-left: 40px;
}

.HeroLogo {
  width: 300px;
  display: block;
  margin: 0 auto;
}
.ButtonWrapper {
  padding-top: 20px;
}