.InlineInput {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  flex: 1;
  order: 1;
}

.InlineInput:valid + .InlineButton {
  background-color: var(--color-success);
  color: var(--color-primary);
  transition:
    background-color 0.2s,
    color 0.2s;
}

.InlineButton {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
