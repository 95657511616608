:root {
}

.Button {
  align-items: center;
  appearance: none;
  background-color: var(--color-tertiary);
  border-radius: var(--base-border-radius);
  border: 1px solid var(--color-tertiary);
  color: var(--color-primary);
  cursor: pointer;
  display: inline-flex;
  font-family: var(--font-family-body);
  font-size: var(--base-font-size);
  font-weight: var(--font-weight-bold);
  justify-content: center;
  min-height: var(--form-input-height);
  outline: 0;
  padding: 0 var(--base-gutter);
  text-decoration: none;
}

.Button:active {
  opacity: 0.8;
}

.Button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.fullWidth {
  width: 100%;
}

.Danger {
  background-color: var(--color-error);
  color: var(--color-primary);
}

.Success {
  background-color: var(--color-success);
  color: var(--color-primary);
}

.Tertiary {
  background-color: var(--color-primary);
  border: 1px solid var(--color-tertiary);
  color: var(--color-tertiary);
}

.Button + .Button {
  margin-left: 0.5em;
}

.CashTipButton {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
