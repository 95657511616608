.Dropdown {
  display: block;
  font-size: calc(var(--base-font-size) / 1.5);
  position: relative;
}

.Dropdown[hidden] {
  display: none;
}

.DropdownLabel {
  cursor: pointer;
  display: block;
}

.Dropdown select {
  appearance: none;
  background-color: var(--color-primary);
  border-radius: var(--base-border-radius);
  border: 1px solid transparent;
  cursor: pointer;
  display: block;
  font-size: var(--base-font-size);
  padding-bottom: var(--base-gutter);
  padding-left: calc(var(--base-gutter) * 1.5);
  padding-right: var(--base-gutter);
  padding-top: var(--base-gutter);
  text-transform: capitalize;
  width: 100%;
}

.Dropdown select:invalid {
  border-color: var(--color-error);
}

.Dropdown::before {
  content: '▼';
  font-size: 0.8em;
  left: 1em;
  pointer-events: none;
  position: absolute;
  transform:
    translateY(-50%);
  top: 50%;
}
