  .MultiSelectDropdown {
    display: block;
    font-size: calc(var(--base-font-size) / 1.5);
    position: relative;
  }
  
  .MultiSelectDropdownLabel {
    cursor: pointer;
    display: block;
  }
