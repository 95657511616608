:root {
  --base-font-size: 20px;
  --base-gutter: 21px;
  --base-border-radius: 0.200em;

  --color-primary: #ffffff;
  --color-secondary: #f2f2f2;
  --color-tertiary: #000000;
  --color-error: #C81D25;
  --color-success: #19B35C;
  --color-warning: #ccb610;

  --font-family-body: 'Open Sans', sans-serif;
  --font-family-header: 'Karla', sans-serif;
  --font-weight-regular: 400;
  --font-weight-bold: 700;

  --form-input-height: 3.45em;

  --h1-size: 24px;

  --flex-direction: column;

/*
  smartphone portrait: (max-device-width : 320px);
  smartphone landscape: (max-device-width : 480px);
  tablet portrait: (min-device-width : 768px);
  tablet landscape: (min-device-width : 768px) and (orientation: landscape);
  medium screen: (min-device-width : 1224px);
  large screen: (min-device-width : 1824px);
*/
}

@media only screen and (min-width : 768px) {
  :root {
    --flex-direction: row;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: block;
  height: 100%;
  width: 100%
}

body {
  background-color: var(--color-secondary);
  font-family: var(--font-family-body);
  font-size: var(--base-font-size);
}

h1 {
  font-size: var(--h1-size);
  font-weight: var(--font-weight-regular);
}

main {
  margin: 0 auto;
  width: 90%;
}

/* Helpers */
.ff-body { font-family: var(--font-family-body) }
.ff-header { font-family: var(--font-family-header) }

.bg-primary { background-color: var(--color-primary); }
.bg-secondary { background-color: var(--color-secondary); }
.bg-tertiary { background-color: var(--color-tertiary); }

.c-primary { color: var(--color-primary); }
.c-secondary { color: var(--color-secondary); }
.c-tertiary { color: var(--color-tertiary); }

.db { display: block; }
.dt { display: table; }

.full-width { width: 100% }

.pa { padding: var(--base-gutter); }
.pa2 { padding: calc(var(--base-gutter) * 2); }

.pt { padding-top: var(--base-gutter); }
.pt2 { padding-top: calc(var(--base-gutter) * 2); }
.pt- { padding-top: calc(var(--base-gutter) / 2); }

.pl { padding-left: var(--base-gutter); }
.pl2 { padding-left: calc(var(--base-gutter) * 2); }
.pl- { padding-left: calc(var(--base-gutter) / 2); }

.pr { padding-right: var(--base-gutter); }
.pr2 { padding-right: calc(var(--base-gutter) * 2); }
.pr- { padding-right: calc(var(--base-gutter) / 2); }

.pb { padding-bottom: var(--base-gutter); }
.pb2 { padding-bottom: calc(var(--base-gutter) * 2); }
.pb- { padding-bottom: calc(var(--base-gutter) / 2); }

.mt { margin-top: var(--base-gutter); }
.mt2 { margin-top: calc(var(--base-gutter) * 2); }
.mt- { margin-top: calc(var(--base-gutter) / 2); }
.mt0 { margin-top: 0; }

.ml { margin-left: var(--base-gutter); }
.mla { margin-left: auto; }
.ml2 { margin-left: calc(var(--base-gutter) * 2); }
.ml- { margin-left: calc(var(--base-gutter) / 2); }

.mr { margin-right: var(--base-gutter); }
.mra { margin-right: auto; }
.mr2 { margin-right: calc(var(--base-gutter) * 2); }
.mr- { margin-right: calc(var(--base-gutter) / 2); }

.mb { margin-bottom: var(--base-gutter); }
.mb2 { margin-bottom: calc(var(--base-gutter) * 2); }
.mb- { margin-bottom: calc(var(--base-gutter) / 2); }
.mb0 { margin-bottom: 0 !important; }

.tac {
  text-align: center;
}

.required::after {
  color: var(--color-error);
  content: '*';
}

.error-message {
  color: var(--color-error);
}
.error-message::before {
  content: '!';
  font-weight: bold;
}

.success-message {
  color: var(--color-success);
}
.success-message::before {
  content: '✓';
}

.warning-message {
  color: var(--color-warning);
}
.warning-message::before {
  content: '⚠️';
}

.error-message::before,
.success-message::before,
.warning-message::before {
  margin-right: 0.2em;
}

.br {
  border-radius: var(--base-border-radius);
}
